// @flow
import { NoIndexMetaTag, PageContent, SEO } from "@components";
import { Analytics } from "@containers";
import { Category } from "@utils";
import { Constants } from "@utils";
import { StorageContext } from "@utils/BrowserStorage/StorageContext";
import { PAGE_TYPES } from "@utils/Constants";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";

import { determineStyle } from "../utils";
import { loadLocalStorageId } from "../utils/LocalStorage";

const PageSearchTemplate = ({
    data: {
        navigation,
        footer,
        channel,
        market,
        markets: { nodes: markets },
        languages: { nodes: languages },
        mainCategories: { nodes: mainCategories },
        subCategories: { nodes: subCategories },
        site: {
            siteMetadata: { siteUrl },
        },
        noResults,
        initialResults,
    },
    query,
    location,
}: *) => {
    const translations = languages.map(({ code }) => ({
        node_locale: code,
        slug: "search",
    }));

    const categories = mainCategories.concat(subCategories);

    const content = {
        __typename: "search",
        searchLocation: `/${
            market.node_locale
        }-${market.code.toLowerCase()}/search`,
        categories: categories.map(category =>
            Category.getDestinationForCategory(
                {
                    language: market.node_locale,
                    market,
                },
                category,
            ),
        ),
        noResults: noResults?.content,
        initialResults: initialResults?.content,
    };
    const navData = determineStyle("Default", navigation);
    return (
        <LocaleContext.Provider
            value={{
                language: market.node_locale,
                market,
            }}
        >
            <StorageContext.Provider
                value={loadLocalStorageId(Constants.QUERY_PARAMETERS)}
            >
                <Analytics.PageWrapper
                    key={`${market.id}-search`}
                    entry={`${market.id}-search`}
                    type={PAGE_TYPES.SEARCH}
                    title="Search"
                    language={market.node_locale}
                    urlParameters={query}
                    slug={"search"}
                    segments={null}
                >
                    <SEO
                        title="Search"
                        translations={translations}
                        publishedMarkets={markets}
                        metaDescription="Search the content of the blog"
                        siteUrl={siteUrl}
                        currentPath={location.pathname}
                    />
                    <NoIndexMetaTag channel={channel} />
                    <PageContent
                        navigation={navData}
                        footer={footer}
                        translations={translations}
                        slug="search"
                        entry={content}
                        publishedMarkets={markets}
                        enableScrollTop
                        hideCategoryBar
                    />
                </Analytics.PageWrapper>
            </StorageContext.Provider>
        </LocaleContext.Provider>
    );
};

export default withURLParameters(PageSearchTemplate);

export const query = graphql`
    query(
        $language: String!
        $marketCode: String!
        $channel: String!
        $categoryId: String = null
        $id: String = null
    ) {
        ...SiteMetadata
        channel: contentfulChannel(channel: { eq: $channel }) {
            noIndex
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        markets: allContentfulMarket(
            filter: { node_locale: { eq: $language } }
        ) {
            nodes {
                ...MarketGeneral
            }
        }
        languages: allTpoContentfulLocale {
            nodes {
                code
            }
        }
        mainCategories: allContentfulMainCategory(
            filter: {
                node_locale: { eq: $language }
                channels: { elemMatch: { channel: { eq: $channel } } }
            }
        ) {
            nodes {
                id
                contentful_id
                label
                slug
                node_locale
            }
        }
        subCategories: allContentfulSubCategory(
            filter: {
                node_locale: { eq: $language }
                channels: { elemMatch: { channel: { eq: $channel } } }
            }
        ) {
            nodes {
                id
                contentful_id
                label
                slug
                node_locale
            }
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }
        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
        noResults: contentfulPageTechnical(
            channels: { elemMatch: { channel: { eq: $channel } } }
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            pageType: { eq: "search: no results" }
        ) {
            content {
                ...VisionaryBanner
                ...TextBlock
                ...Grid
                ...CallToAction
                ...DisplayBoxedContent
                ...DisplayDownloadBlock
                ...VariantContainer
            }
        }
        initialResults: contentfulPageTechnical(
            channels: { elemMatch: { channel: { eq: $channel } } }
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            pageType: { eq: "search: empty" }
        ) {
            content {
                ...VisionaryBanner
                ...TextBlock
                ...Grid
                ...CallToAction
                ...DisplayBoxedContent
                ...DisplayDownloadBlock
                ...VariantContainer
            }
        }
    }
`;
